import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceThree from '../elements/service/ServiceThree';
import Separator from "../elements/separator/Separator";
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';

const BusinessConsulting2 = () => {
    return (
        <>
            <SEO title="Services | FTD | FINANCE TECH DEVELOPMENT CONSULTANCY" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />
                <BreadcrumbOne
                    title="Services"
                    rootUrl="/services"
                    parentUrl="Home"
                    currentUrl="Services"
                />
                <Separator />
                <div className="rn-service-area rn-section-gapBottom">
                    <div className="container">
                        <ServiceThree
                            serviceStyle="gallery-style"
                            textAlign="text-left"
                        />
                    </div>
                </div>
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default BusinessConsulting2;
