import React, { useState, useEffect } from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Separator from "../elements/separator/Separator";
import ScrollAnimation from 'react-animate-on-scroll';
import ServiceList from "../models/serviceList"
import BreadcrumbService from '../elements/breadcrumb/BreadcrumbService';

const Finance = (props) => {
    const [content, setContent] = useState({})

    const serviceLink = props.location.search.replace("?s=", "")
    useEffect(() => {
        ServiceList.map(item => {
            if(item.link === serviceLink){
                setContent(item)
            }else return null
        })
    }, [])

    return (
        <>
            <SEO title={content.title + " | FTD | FINANCE TECH DEVELOPMENT CONSULTANCY"} />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />
                <BreadcrumbService
                    title={content.title}
                    rootUrl="/services"
                    parentUrl="Home"
                    currentUrl={content.title}
                />
                <Separator />
                <div className="rwt-about-area about-style-2 rn-section-gap">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src={content.image} alt="About Images" />
                                </div>
                            </div>

                            <div className="col-lg-7 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="section-title">
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <h4 className="subtitle"><span className="theme-gradient">Services</span></h4>
                                            <h2 className="title mt--10">{content.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>

                                            <div dangerouslySetInnerHTML={{ __html: content.detail }}></div>

                                            {/* <ul className="list-icon">
                                                <li><span className="icon"><FiCheck /></span> Track your teams progress with mobile app.</li>
                                                <li><span className="icon"><FiCheck /></span> Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                                                <li><span className="icon"><FiCheck /></span> Ipsum dolor sit amet consectetur adipisicing.</li>
                                                <li><span className="icon"><FiCheck /></span> Your teams progress with mobile app.</li>
                                            </ul> */}
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterTwo />
            </main>
        </>
    )
}

export default Finance;
