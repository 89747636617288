import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import { FiArrowRight } from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";

const BusinessConsulting2 = () => {
    return (
        <>
            <SEO title="FTD | FINANCE TECH DEVELOPMENT CONSULTANCY" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="display-two">FTD Consultancy</h1>
                                    <h1 className="title theme-gradient display-two">
                                        <Typed
                                            strings={[
                                                "Software",
                                                "CRM",
                                                "Network",
                                                "Central"
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <br />
                                    <h1 className="display-two">With You !</h1>
                                    <div className="button-group mt-5">
                                        <a className="btn-default btn-medium round btn-icon" href="/services">Our Services <i className="icon"><FiArrowRight /></i></a>
                                        <a className="btn-default btn-medium btn-border round btn-icon" href="/contact">Contact Us <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Separator />
                <AboutOne />
                <Separator />
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Our Services"
                                    description="With our long-standing experience and expert staff, we provide many services in the field of technology in a high quality and fast manner."
                                />
                            </div>
                        </div>
                        <ServiceFive
                            serviceStyle="gallery-style"
                            textAlign="text-left"
                        />
                    </div>
                </div>
                <div className="rwt-callto-action-area" style={{ marginBottom: "100px" }}>
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default BusinessConsulting2;
