import React from 'react';
import SEO from "../common/SEO";
import Separator from "../elements/separator/Separator";
import HeaderOne from '../common/header/HeaderOne';
import ServiceOne from '../elements/service/ServiceOne';
import AboutFour from '../elements/about/AboutFour';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';
import Typed from 'react-typed';

const WebAgency = () => {
    return (
        <>
            <SEO title="About Us | FTD | FINANCE TECH DEVELOPMENT CONSULTANCY" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />
                <BreadcrumbOne
                    title="About Us"
                    rootUrl="/about-us"
                    parentUrl="Home"
                    currentUrl="About Us"
                />
                <Separator />
                <AboutFour image="./images/about/contact-image.jpg" />
                <div className="rn-service-area mb--70 ">
                    <div className="container">
                        <ServiceOne
                            serviceStyle="service__style--1"
                            textAlign="text-left"
                        />
                    </div>
                </div>
                <Separator />
                <div className="service-area rn-section-gapTop bg-dark">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="content">
                                    <h3 className="title text-light pb--90 text-center">We produce {" "}           
                                                        
                                    <span className="theme-gradient">
                                        <Typed
                                            strings={[
                                                "trust",
                                                "quality",
                                                "realistic solutions",
                                                "principles of compliance"
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </span>
                                    <br/>   
                                    with international standards and take care to serve you in line with these principles.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Separator />
                <div className="service-area rn-section-gapBottom mt--100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Why {" "}
                                        <span className="theme-gradient">
                                            <Typed
                                                strings={[
                                                    "FTD Consultancy",
                                                    "We Are",
                                                    "You Should Choose Us"
                                                ]}
                                                typeSpeed={80}
                                                backSpeed={5}
                                                backDelay={1000}
                                                loop
                                            />
                                        </span>
                                    </h3>
                                    <p className="mt--60">
                                        We look at every project with a new enthusiasm.
                                        It is an indescribable source of happiness for us to produce new ideas, offer new solutions, and work with new people.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img src="images/ftd/about/about-2.png" alt="about-2" />
                            </div>
                        </div>
                    </div>
                </div>

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default WebAgency;