import React from 'react'

const AboutFour = () => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6 text-center">
                    <img src="images/ftd/about/about-1.png" alt="about-1" />
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title"><b>About Us</b></h3>
                                <p>
                                    FTD Software is a company that has been operating for many years and aims to provide the best service to its customers without compromising on quality and trust.
                                </p>
                                <p>
                                    We produce trust, quality, realistic solutions, adopt the principles of compliance with international standards and take care to serve you in line with these principles.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
